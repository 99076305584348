import request from '../utils/request';


//-------------------------------------------器材订单↓↓↓ 器材提报的记录----------------------------------------------------------------
/**
 * 器材订单 申请列表
 * @param regionId
 * @param coachName
 * @param page
 * @param pageSize
 * @returns {AxiosPromise}
 */
export const equCheckList = (list) => {
    return request({
        url: '/sys/equipment/page',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
// /***
//  * 导出
//  * @param query
//  * @returns {AxiosPromise}
//  */
// export function exportExcel(query) {
//     return request({
//         url: '/sys/city/exportEqu',
//         method: 'POST',
//         responseType: 'arraybuffer',
//         dataType: "json",
//         data: JSON.stringify(query),
//         headers:{
//             'Content-Type':'application/json;charset=UTF-8',
//         }
//     })
// }
/**
 * 器材订单 根据id 查询领取记录
 * @param {Object}
 */

export const selectGetRecordById = (id) => {
    return request({
        url: '/sys/equipment/selectGetRecordById',
        method: 'get',
        params: {id},
    })
};
/**
 * 器材订单 提报的器材订单 导出Excel
 * @param query
 * @returns {AxiosPromise}
 */
export const excel = (query) => {
    return request({
        url: '/sys/equipment/excel',
        method: 'post',
        data: query,
        responseType: 'arraybuffer'
    });
};
/**
 * 导出 器材城市校区汇总的 Excel
 * @param query
 * @returns {AxiosPromise}
 */
export const exportEquipSummaryExcel = (query) => {
    return request({
        url: '/sys/equipment/SummaryExcel',
        method: 'post',
        data: query,
        responseType: 'arraybuffer'
    });
};

/**
 * 器材订单 删除器材订单
 * @param id
 * @returns {AxiosPromise}
 */
export const delEquCheck = (id) => {
    return request({
        url: '/sys/equipment/delEquCheck',
        method: 'get',
        params: {
            equCheckId: id
        }
    });
};


//-------------------------------------------器材管理↓↓↓  器材本身----------------------------------------------------------------
/**
 * 器材管理 分页列表
 * @param query
 * @returns {*}
 */
export const equList = (query) => {
    return request({
        url: '/sys/equipment/pageEquipment',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
        }
    });
};
/**
 * 器材管理 删除器材
 * @param id
 * @returns {AxiosPromise}
 */
export const del = (id) => {
    return request({
        url: '/sys/equipment/equDel',
        method: 'get',
        params: {
            id
        }
    });
};
/**
 * 器材管理 修改器材的状态
 * @param id
 * @param state
 * @returns {AxiosPromise}
 */
export const editState = (id, state) => {
    return request({
        url: '/sys/equipment/updateState',
        method: 'get',
        params: {
            id,
            state
        }
    });
};


/**
 * 器材管理 新增器材
 * @param form
 * @returns {AxiosPromise}
 */
export const add = (form) => {
    return request({
        url: '/sys/equipment/equAdd',
        method: 'post',
        data: form
    });
};

/**
 * 器材管理 修改器材
 * @param form
 * @returns {AxiosPromise}
 */
export const edit = (form) => {
    return request({
        url: '/sys/equipment/equEdit',
        method: 'post',
        data: form
    });
};
//器材挑拨
export const equipTransfer = (form) => {
    return request({
        url: '/sys/equipment/equipTransfer',
        method: 'post',
        data: form
    });
};
//器材同步
export const equipSync = (form) => {
    return request({
        url: '/sys/equipment/equipSync',
        method: 'post',
        data: form
    });
};


export const equipmentMovements = (query) => {
    return request({
        url: '/sys/equipmentMovements/getEquipmentAddMovementsList',
        method: 'post',
        data: query
    });
};

export const getEquipmentAddMovementsListExcel = (query) => {
    return request({
        url: '/sys/equipmentMovements/getEquipmentAddMovementsListExcel',
        method: 'post',
        data: query
    });
};