<template>
  <div v-loading="loading"
       element-loading-text="正在导出数据">
    <div style="padding: 20px">
      <div class="handle-box">
        <select-area @areaListId="getAreaListId"/>
        <el-input v-model="query.equipmentName" style="width: 100px" placeholder="器材名称" class="handle-input mr10"></el-input>
        <el-select v-model="query.status" placeholder="状态" class="handle-select mr10">
          <el-option key="0" label="全部" value=""></el-option>
          <el-option key="2" label="待审核" value="1"></el-option>
          <el-option key="2" label="同意" value="2"></el-option>
          <el-option key="3" label="拒绝" value="3"></el-option>
          <el-option key="3" label="撤销" value="4"></el-option>
        </el-select>
        <el-input v-model="query.keyword" style="width: 100px" placeholder="发起人名字" class="handle-input mr10"></el-input>
        <el-input v-model="query.studentName" style="width: 100px" placeholder="学员姓名"
                  class="handle-input mr10"></el-input>
        <el-date-picker
            style="width: 260px"
            class="handle-input"
            v-model="v1"
            type="daterange"
            range-separator="至"
            :clearable=false
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <el-date-picker
            style="width: 260px;margin-left:10px;"
            class="handle-input"
            v-model="v2"
            :clearable=false
            type="daterange"
            range-separator="至"
            start-placeholder="审核开始日期"
            end-placeholder="审核结束日期">
        </el-date-picker>
        <el-button type="primary" style="margin-left: 20px"  @click="handleSearch">搜索</el-button>
        <el-button type="info"  @click="reset">重置搜索条件</el-button>
        <el-button type="primary"  @click="exportExcel">导出器材明细表</el-button>

        <el-button type="primary"  @click="exportEquipSummaryExcel">导出城市校区器材汇总表</el-button>
      </div>
      <el-table
          :data="tableData"
          v-loading="tableLoading"
          height="610px"
          style="width: 100%;margin-bottom: 20px;"
          :row-style="{height:0+'px'}"
          :cell-style="{padding:0+'px'}"
          row-key="id"
          border>

        <el-table-column
            prop="studentName"
            label="学生姓名"
            width="140">
          <template #default="scope">{{scope.row.studentName}}({{ scope.row.studentId }})</template>
        </el-table-column>
        <el-table-column
            prop="studentPhone"
            label="学生电话"
            sortable
            width="120">
        </el-table-column>
        <el-table-column
            label="上课校区"
            width="120">
          <template #default="scope">{{ scope.row.studentCourseCampusName }}</template>
        </el-table-column>
        <el-table-column
            width="160"
            prop="cardtype.cardname"
            label="器材名称">
          <template #default="scope">
            <el-popover
                v-if="scope.row.childCheckList != null"
                placement="top-start"
                :width="260"
                trigger="click"
            >
              <div v-for="item in scope.row.childCheckList" :key="item.id">
                <el-tooltip
                    class="box-item"
                    effect="dark"
                    :content="item.equipmentName"
                    placement="top"
                >
                  <div style="display:flex;">
                    <el-tag
                        style="width: 130px;margin-bottom:10px;text-align: center;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                      {{ item.equipmentName }}
                    </el-tag>
                    <el-tag style="width: 50px;text-align: center;margin-left: 10px">
                      × {{ item.num }}
                    </el-tag>
                    <el-tag style="width: 50px;text-align: center;margin-left: 10px">
                      × {{ item.money }}
                    </el-tag>
                  </div>
                </el-tooltip>

              </div>
              <template #reference>
                <el-tag style="width: 100px;text-align: center" class="sug_info">查看器材</el-tag>
              </template>
            </el-popover>
            <el-tag v-else type="info" style="width: 100px;text-align: center" class="sug_info">暂无信息</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="发起人"
            sortable
            width="90">
          <template #default="scope">{{ scope.row.coachName }}</template>
        </el-table-column>
        <el-table-column
            width="100"
            show-overflow-tooltip="true"
            prop="areaName"
            label="区域">
        </el-table-column>
<!--        <el-table-column
            label="所属校区"
            width="160">
          <template #default="scope">{{ scope.row.campusName }}</template>
        </el-table-column>-->
        <el-table-column
            width="100"
            show-overflow-tooltip="true"
            label="类型">
          <template v-slot="scope">
            <el-tag>{{ scope.row.flagName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="审批状态"
            width="100">
          <template #default="scope">
            <el-tag
                :type="scope.row.status == 1 ? 'info':(scope.row.status == 2?'success':(scope.row.status == 3?'danger':scope.row.status == 5?'success':'warning')) "
            >
              {{
                scope.row.statusName
              }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column width="90px" label="收款图片">
          <template v-slot="scope">
            <el-image
              :preview-teleported="true"
              class="code"
              :src="scope.row.imgUrl != null? JSON.parse(scope.row.imgUrl)[0]:''"
              :preview-src-list="JSON.parse(scope.row.imgUrl)">
              <template #error>
                <div class="image-slot">
                  <span class="demonstration">暂无图片</span>
                </div>
              </template>
            </el-image>
          </template>
        </el-table-column>

        <el-table-column
            prop="totalMoney"
            label="总金额(元)"
            width="100">
        </el-table-column>

        <el-table-column
            prop="payDate"
            label="收款日期"
            sortable
            width="160">
        </el-table-column>

        <el-table-column
            prop="createTime"
            label="申请时间"
            sortable
            width="160">
        </el-table-column>
        <el-table-column
            prop="checkTime"
            label="审核时间"
            sortable
            width="160">
        </el-table-column>

        <el-table-column
            label="审批人"
            sortable
            width="90">
          <template #default="scope">{{ scope.row.checkCoachName }}</template>

        </el-table-column>
        <el-table-column
            label="是否业绩"
            width="90">
          <template #default="scope">
            <div v-if="scope.row.isPayment===1">
              <el-popover
                  placement="top"
                  title="支付记录"
                  :width="340"
                  trigger="hover"
              >
                <div>
                  <span>支付单号：{{ scope.row.tradingRecord.transaction }}</span>
                </div>
                <template #reference>
                  <el-switch v-model="scope.row.isPayment" disabled :active-value="1" :inactive-value="0"/>
                </template>
              </el-popover>
            </div>
            <el-switch v-else v-model="scope.row.isPayment" disabled :active-value="1" :inactive-value="0"/>
          </template>
        </el-table-column>
        <el-table-column
            label="是否快递"
            width="90">
          <template #default="scope">
            <div v-if="scope.row.isLogistics===1">
              <el-popover
                  placement="top"
                  title="收货信息"
                  :width="260"
                  trigger="hover"
              >
                <div>
                  <div>姓名：{{ scope.row.expressage.name }}
                    <el-divider style="margin: 0 16px" direction="vertical"></el-divider>
                    电话：{{ scope.row.expressage.phone }}
                  </div>
                  <div style="margin: 8px 0">收货地址：{{ scope.row.expressage.region }}</div>
                  <div>详细地址：{{ scope.row.expressage.detailedLocation }}</div>
                </div>
                <template #reference>
                  <el-switch v-model="scope.row.isLogistics" disabled :active-value="1" :inactive-value="0"/>
                </template>
              </el-popover>
            </div>
            <el-switch v-else v-model="scope.row.isLogistics" disabled :active-value="1" :inactive-value="0"/>
          </template>
        </el-table-column>

        <el-table-column
            prop="remark"
            label="备注"
            show-overflow-tooltip="true"
            width="130">
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right" align="center">
          <template #default="scope">
            <el-button
                v-permission="['equip:delete']"
                type="text"
                icon="el-icon-delete"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>


  </div>
</template>

<script>
import {equCheckList, excel, delEquCheck, exportEquipSummaryExcel} from "@/api/equipment";
import selectArea from "../../components/SelectArea";

export default {
  components: {
    selectArea,
  },
  name: "EquipmentLq",
  data() {
    return {
      query: {
        equipmentName:'',
        keyword: "",
        areaList: [],
        studentName: "",
        pageIndex: 1,
        pageSize: 10,
        startDate:'',
        endDate:'',
        checkStartDate:'',
        checkEndDate:''
      },
      rules: {
        name: [
          {required: true, message: '请输入器材名称', trigger: 'blur'},
        ],
        regionId: [
          {required: true, message: '请选择所属城市', trigger: 'change'}
        ]
      },
      v1: [],
      v2:[],
      loading: false,
      isDisabled: true,
      eqList: [],
      regionList: [],
      campusList: [],
      campusList1: [],
      tableData: [],
      tableLoading: false,
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      form: {},
      addForm: {},
      idx: -1,
      id: -1
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getAreaListId(val) {
      this.query.areaList = val;
    },

    //获取数据
    getData() {
      this.tableLoading = true;
      //获取提报的器材的表格数据
      equCheckList(this.query).then(res => {
        this.tableLoading = false;
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.pageTotal = res.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      }).catch(() => {
      })
    },

    // 触发搜索按钮
    handleSearch() {
      if (this.v1 && this.v1.length >= 2) {
        this.query.startDate = this.dateFormat(this.v1[0]);
        this.query.endDate = this.dateFormat(this.v1[1]);
      }
      if (this.v2 && this.v2.length >= 2) {
        this.query.checkStartDate = this.dateFormat(this.v2[0]);
        this.query.checkEndDate = this.dateFormat(this.v2[1]);
      }
      this.query.pageIndex = 1
      this.getData();
    },
    // 删除器材订单操作
    handleDelete(index, row) {

      console.info(row);
      let str = ""
      if (row.childCheckList) {
        str = "将会删除该器材提报订单下的所有器材噢!"
      }
      // 二次确认 删除器材订单操作
      this.$confirm("确定要删除 学生(" + row.studentName + ")的器材订单, 吗? " + str, "提示", {
        type: "warning"
      }).then(() => {
        delEquCheck(row.id).then(res => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.tableData.splice(index, 1);
            this.getData()
          } else {
            this.$message.error("删除失败");
          }
        })
      }).catch(() => {
      });
    },

    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },
    // 重置搜索条件
    reset() {
      this.query.equipmentName = ''
      this.query.regionId = ""
      this.v1 = []
      this.v2 = []
      this.query.startDate = ""
      this.query.endDate = ""
      this.query.checkStartDate = ""
      this.query.checkEndDate = ""
      this.query.campusId = ""
      this.query.status = ""
      this.query.keyword = ""
      this.query.pageIndex = 1
      this.getData()
    },
    dateFormat(val) {
      var date = new Date(val)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },
    //导出器材城市校区汇总表
    exportEquipSummaryExcel(){
      // 二次确认删除
      this.$confirm("确定要导出数据 器材明细表 吗?", "提示", {
        type: "warning"
      }).then(() => {
        //执行loading显示
        this.loading = true;
        //日期翻转
        if (this.v1.length >= 2) {
          this.query.startDate = this.dateFormat(this.v1[0]);
          this.query.endDate = this.dateFormat(this.v1[1]);
        }
        //日期翻转
        if (this.v2.length >= 2) {
          this.query.checkStartDate = this.dateFormat(this.v2[0]);
          this.query.checkEndDate = this.dateFormat(this.v2[1]);
        }
        //执行导出
        exportEquipSummaryExcel(this.query).then(res => {
          console.log(res)
          this.loading = false;
        })
      }).catch(() => {
      });
    },
    // 导出提报的订单器材 excel
    exportExcel() {
      // 二次确认删除
      this.$confirm("确定要导出数据 器材明细表 吗?", "提示", {
        type: "warning"
      }).then(() => {
        this.loading = true;
        if (this.v1.length >= 2) {
          this.query.startDate = this.dateFormat(this.v1[0]);
          this.query.endDate = this.dateFormat(this.v1[1]);
        }
        if (this.v2.length >= 2) {
          this.query.checkStartDate = this.dateFormat(this.v2[0]);
          this.query.checkEndDate = this.dateFormat(this.v2[1]);
        }
        excel(this.query).then(res => {
          console.log(res)
          this.loading = false;
          // let blob = new Blob([res], {
          //   type: "application/vnd.ms-excel"
          // });
          // console.log(blob);
          // let objectUrl = URL.createObjectURL(blob);
          // console.log(objectUrl);
          // const link = document.createElement('a'); //我们用模拟q标签点击事件
          // var myDate = new Date(); //下载文件的名字
          // link.href = objectUrl;
          // link.setAttribute('download', this.dateFormatC(myDate) + "订单");
          // document.body.appendChild(link);
          // /**
          //  * 在这期间，我们可以做自己界面的反应逻辑
          //  **/
          // link.click(); //点击
          // document.body.removeChild(link); // 下载完成移除元素
          // window.URL.revokeObjectURL(URL); // 释放掉blob对象
        })
      }).catch(() => {
      });
    },
    dateFormatC(val) {
      var myDate = new Date(val);
      var year = myDate.getFullYear();       //年
      var month = myDate.getMonth() + 1;     //月
      var day = myDate.getDate();            //日
      var hh = myDate.getHours();            //时
      var mm = myDate.getMinutes();
      var ss = myDate.getSeconds();
      const fname = '订单表' + year + '年' + month + '月' + day + '日' + hh + '时' + mm + '分' + ss + '秒';
      return fname;
    }
  }
};
</script>

<style scoped>

.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 6px
}
</style>
<style lang="scss">
.el-popover.my-el-popover {
  width: 20px;
}

.el-cascader__tags {
  display: inline-flex;
  margin-right: 60px;
  flex-wrap: nowrap;
}
</style>
